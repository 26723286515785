<template>
  <div>
    <MenuLegals />

    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import MenuLegals from "@/components/menu-legals.vue";

export default {
  name: "Mailbox",

  components: {
    MenuLegals,
  },
};
</script>

<style scoped></style>
